@import '../../styles/customMediaQueries.css';

.container {
  position: relative;
  width: 100%;
  max-width: 240px;
  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.inputWrapper {
  position: relative;
  &:after {
    position: absolute;
    content: '';
    left: 19px;
    top: 19px;
    height: 27px;
    width: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='27' viewBox='0 0 24 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 13.5C12.825 13.5 13.5315 13.2354 14.1195 12.7062C14.7065 12.1779 15 11.5425 15 10.8C15 10.0575 14.7065 9.42165 14.1195 8.89245C13.5315 8.36415 12.825 8.1 12 8.1C11.175 8.1 10.469 8.36415 9.882 8.89245C9.294 9.42165 9 10.0575 9 10.8C9 11.5425 9.294 12.1779 9.882 12.7062C10.469 13.2354 11.175 13.5 12 13.5ZM12 23.4225C15.05 20.9025 17.3125 18.6129 18.7875 16.5537C20.2625 14.4954 21 12.6675 21 11.07C21 8.6175 20.131 6.60915 18.393 5.04495C16.656 3.48165 14.525 2.7 12 2.7C9.475 2.7 7.3435 3.48165 5.6055 5.04495C3.8685 6.60915 3 8.6175 3 11.07C3 12.6675 3.7375 14.4954 5.2125 16.5537C6.6875 18.6129 8.95 20.9025 12 23.4225ZM12 27C7.975 23.9175 4.969 21.0541 2.982 18.41C0.994 15.7667 0 13.32 0 11.07C0 7.695 1.2065 5.00625 3.6195 3.00375C6.0315 1.00125 8.825 0 12 0C15.175 0 17.9685 1.00125 20.3805 3.00375C22.7935 5.00625 24 7.695 24 11.07C24 13.32 23.0065 15.7667 21.0195 18.41C19.0315 21.0541 16.025 23.9175 12 27Z' fill='%23E27757'/%3E%3C/svg%3E ");
    @media (max-width: 767px) {
      background-size: 16px;
      height: 23px;
      width: 18px;
      top: 15px;
      right: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='23' viewBox='0 0 18 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 11.5C9.61875 11.5 10.1486 11.2746 10.5896 10.8238C11.0299 10.3738 11.25 9.8325 11.25 9.2C11.25 8.5675 11.0299 8.02585 10.5896 7.57505C10.1486 7.12502 9.61875 6.9 9 6.9C8.38125 6.9 7.85175 7.12502 7.4115 7.57505C6.9705 8.02585 6.75 8.5675 6.75 9.2C6.75 9.8325 6.9705 10.3738 7.4115 10.8238C7.85175 11.2746 8.38125 11.5 9 11.5ZM9 19.9525C11.2875 17.8058 12.9844 15.8554 14.0906 14.1013C15.1969 12.3479 15.75 10.7908 15.75 9.43C15.75 7.34083 15.0982 5.63002 13.7947 4.29755C12.492 2.96585 10.8938 2.3 9 2.3C7.10625 2.3 5.50763 2.96585 4.20412 4.29755C2.90138 5.63002 2.25 7.34083 2.25 9.43C2.25 10.7908 2.80312 12.3479 3.90937 14.1013C5.01562 15.8554 6.7125 17.8058 9 19.9525ZM9 23C5.98125 20.3742 3.72675 17.935 2.2365 15.6825C0.7455 13.4309 0 11.3467 0 9.43C0 6.555 0.904875 4.26458 2.71463 2.55875C4.52363 0.852917 6.61875 0 9 0C11.3812 0 13.4764 0.852917 15.2854 2.55875C17.0951 4.26458 18 6.555 18 9.43C18 11.3467 17.2549 13.4309 15.7646 15.6825C14.2736 17.935 12.0188 20.3742 9 23Z' fill='%23E27757' /%3E%3C/svg%3E%0A");
    }
  }
  &:before {
    position: absolute;
    content: '';
    right: 16px;
    top: 26px;
    height: 27px;
    width: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.5 12.572L0 2.37849L1.98333 0L8.5 7.81505L15.0167 0L17 2.37849L8.5 12.572Z' fill='%232B3740'/%3E%3C/svg%3E%0A");
    @media (max-width: 767px) {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='11' viewBox='0 0 18 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 10.8167L0.5 2.31668L2.48333 0.333344L9 6.85001L15.5167 0.333344L17.5 2.31668L9 10.8167Z' fill='%232B3740'/%3E%3C/svg%3E%0A");
      background-size: 16px;
      height: 16px;
      width: 16px;
      top: 20px;
      right: 16px;
    }
  }
}

.input {
  outline: none;
  flex-grow: 1;
  padding: 8px 72px 8px 49px;
  font-size: 16px;
  background-color: transparent;
  height: 65px;
  box-shadow: 4px 4px 0px 0px #dfc6b8;
  @media (max-width: 767px) {
    height: 48px;
  }
}

.inputError {
  color: #f2704e;
  outline: none;
  flex-grow: 1;
  padding: 8px 72px 8px 49px;
  font-size: 16px;
  background-color: transparent;
  height: 65px;
  box-shadow: 4px 4px 0px 0px #dfc6b8;
  @media (max-width: 767px) {
    height: 48px;
  }
  &::placeholder {
    color: #f2704e;
  }
}

.iconWrapper {
  display: flex;
  align-items: center;
}

.locationIcon {
  margin-right: 8px;
  color: #e74c3c; /* Red color for the location icon */
}

.dropdownIcon {
  color: #333; /* Dark color for the dropdown icon */
}

.error {
  color: red;
  margin-top: 5px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  z-index: 1000;
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusMedium);
  border-top: 1px solid var(--colorGrey100);
  box-shadow: var(--boxShadowPopup);
  margin-top: 6px;
  @media (max-width: 1023px) {
    margin-top: 0;
  }
}

.dropdownItem {
  padding: 8px 14px;
  cursor: pointer;
}

.dropdownItem:hover,
.highlighted {
  background-color: #e27757;
  color: #fff;
}

.clearLocation {
  border: none;
  position: absolute;
  top: 10px;
  right: 55px;
  cursor: pointer;
  @media(min-width: 768px){
    top: 18px;
  }
  @media(min-width: 1023px){
    top: 18px;
  }
  &:hover {
    background-color: #f4e8e1;
  }
}
